import { useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import DeleteIcon from '@mui/icons-material/Delete';

import Image from '@atoms/Image';
import HoverBox from '@atoms/HoverBox';

import { drawWheel, colors } from './wheel-canvas.service';
import { chromaPaletteParser } from '../helper/chromaPaletteParser';

const TOTAL_NUMBER_OF_ROTATIONS = 5;
const WheelCanvas = ({
  opts, wheelState, setWheelState, setWinner, stopFeature, splitDiv,
  isFullScreen, setIsExploding, setWinnerInfo, winnerInfo, fsWinnerId,
  updateFSMeta, setFsWinnerId,
  removeParticipant, currentRosFeature
}) => {
  const pi = Math.PI;
  const oneFullRotation = 2 * pi;
  const sectorAngle = (2 * pi) / opts.length;

  const winnerIndex = useRef();
  const canvasRef = useRef(null);
  const angle = useRef(0);
  const totalAngle = useRef(0);

  const [radius, setRadius] = useState();
  const [canvasSide, setCanvasSide] = useState();
  const [animate, setanimate] = useState({});

  let chromaColor;
  if (currentRosFeature.type === 'wheel') chromaColor = currentRosFeature.color.choiceCircleBgColor;
  else if (currentRosFeature.type === 'newWheel') chromaColor = currentRosFeature.color.winningWheelBgColor;

  const newColors = chromaPaletteParser().parse(colors, chromaColor);

  const createWheel = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const isResult = false;
    drawWheel(canvas, radius, opts, canvasSide, isResult, chromaColor);
  };

  useEffect(() => {
    const hero = document.getElementById('hero');
    const marginY = 90; // Standard margin from top and bottom
    setRadius(parseInt((hero.clientHeight - marginY) / 2, 10));
    setCanvasSide((hero.clientHeight - marginY + 20));
  }, [isFullScreen]);

  const getWinnerAngle = (index) => { // Returns the median angle point of a sector
    const i = opts.length * 2 - index;
    return (i * sectorAngle) - (sectorAngle / 2);
  };

  const onComplete = () => {
    setWheelState('ready');
    stopFeature();
    setIsExploding(true);
    setFsWinnerId();
  };

  const getRandomArbitrary = (min, max) => Math.random() * (max - min) + min;

  const initiateSpin = () => {
    angle.current = 0;
    totalAngle.current = 0;

    const winnerAngleInRadians = TOTAL_NUMBER_OF_ROTATIONS
      * oneFullRotation
      + getWinnerAngle(winnerIndex.current);
    const winnerAngleInDegrees = ((winnerAngleInRadians * 180) / pi)
      + getRandomArbitrary(0.05, 0.1);

    setanimate({
      rotate: [0, winnerAngleInDegrees],
      transition: {
        delay: 2, duration: 7, ease: [0.25, 0.1, 0.25, 1.0] // Bezier curve points
      }
    });
  };

  const getWinnerFromLot = () => {
    if (opts.length) {
      let randomIndex;
      if (fsWinnerId) {
        randomIndex = opts.findIndex((opt) => opt.id === fsWinnerId);
      } else {
        randomIndex = Math.floor(Math.random() * opts.length);
      }

      const winnerId = opts[randomIndex].id;
      const winnerResult = opts.map((option, i) => ({
        ...option,
        data: option.body,
        isWinner: option.id === winnerId,
        color: newColors[i % newColors.length]
      }));
      setWinner(winnerResult);
      winnerIndex.current = randomIndex;

      // Update FS for other subscribers so they can see the same winner
      updateFSMeta({
        wheel: {
          winnerIndex: randomIndex,
          timestamp: Date.now(),
          winnerId
        }
      });
    }
  };

  const onUpdate = (e) => {
    if (opts.length) {
      const angleInRadians = (e.rotate * pi) / 180;
      const index = (Math.ceil((angleInRadians / oneFullRotation) * opts.length) % opts.length);
      const winIndex = index === 0 ? 0 : (opts.length - index) % newColors.length;
      setWinnerInfo({
        id: opts[(opts.length - index) % opts.length].id,
        value: opts[(opts.length - index) % opts.length].body,
        color: newColors[winIndex]
      });
    }
  };

  useEffect(() => {
    if (opts.length === 0) return;
    if (radius) {
      createWheel();
      if (wheelState === 'spin') {
        getWinnerFromLot();
        initiateSpin();
      }
    }
  }, [radius, wheelState, fsWinnerId]);

  return <>
    {radius
      && <div className='flex gap-x-9'>
        <div style={{ position: 'relative', width: `${canvasSide}px`, height: `${canvasSide}px` }} >
          <motion.canvas
            initial={{ rotate: 0 }}
            onUpdate={onUpdate}
            animate={animate}
            onAnimationComplete={onComplete}
            ref={canvasRef} width={canvasSide} height={canvasSide} />
          <img style={{ top: `${(radius - 7)}px` }} src='/images/wheel_marker.svg' className='absolute -right-9' />
        </div>
        {
          !splitDiv && <div style={{ backgroundColor: winnerInfo?.color }} className="text-white font-Poppins rounded-2xl relative px-4 py-3 text-center md:w-60 lg:w-104 font-medium break-all h-fit">
            {wheelState === 'ready' && opts.length > 2 &&
            <>
              {process.env.NEXT_PUBLIC_ENV_URL !== 'sandbox' && (<div className='absolute -top-2 -right-2'>
                <HoverBox
                  id='delete-icon'
                  variant='white'
                  className='group flex items-center justify-center rounded-full w-9 h-9 bg-white'
                  svg={<DeleteIcon sx={{ color: '#424242' }} />}
                  position='top'
                  toolTipDescription='Remove participant'
                  handleSvgClick={removeParticipant}
                />
              </div>)}
              <Image className="absolute -top-4" src="/images/InteractionPage/crown.svg" />
            </>}

            {winnerInfo?.value && <p className='text-2xl font-semibold truncate'>{winnerInfo.value}</p>}
          </div>
        }
      </div>
    }
  </>;
};

export default WheelCanvas;
