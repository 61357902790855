import {
  useContext, useEffect, forwardRef, useRef, useState, useImperativeHandle
} from 'react';

import { StreamDataContext } from '@components/context/StreamContext';

import {
  createEngagement, updateEngagement, getCommentsDetails
} from '@services/youtube-platform.service';
import { reArrangeOptions } from '@services/utils.service';

import Loader from '@atoms/Atom/Loader';
import ConfettiItems from './ConfettiItems';
import WheelCanvas from './WheelCanvas';
import WheelCanvasResult from './WheelCanvasResult';
import { getFeatureComments } from './wheel-canvas.service';
import NoUserModal from './NoUserModal';

const WinnerWheel = forwardRef(({
  streamId, stopFeature, currentRosFeature, interactionType, startInteraction, setShowRosModel,
  currentRosResult, interactionState, splitDiv, isFullScreen, setShowToast,
  moderationModeRef, updateFSMeta, meta, colorData,
  setInteractionState, setStartInteraction, setIsStarted
}, ref) => {
  const { setFeatureId } = useContext(StreamDataContext);
  const [wheelId, _setWheelId] = useState(null);
  const [newWheelUser, setNewWheelUser] = useState([]);
  const [isExploding, setIsExploding] = useState(false);
  const wheelIdRef = useRef(wheelId);
  const [winnerInfo, setWinnerInfo] = useState(null);
  const [fsWinnerId, setFsWinnerId] = useState();

  const { isRos } = interactionType;
  const [wheelState, setWheelState] = useState(false);
  const [isResultView, setIsResultView] = useState(false);
  const { hiddenData, setHiddenData } = useContext(StreamDataContext);
  const [opts, setOpts] = useState(currentRosFeature.options);
  const [winner, setWinner] = useState(null);
  const [result, setResult] = useState();
  const [noUser, setNoUser] = useState(false);

  const setWheelId = (currData) => {
    wheelIdRef.current = currData;
    _setWheelId(currData);
  };

  const setInteraction = ({ engagementId }) => {
    setShowRosModel(false);
    setFeatureId(engagementId);
    setWheelId(engagementId);
  };

  const createWheel = async () => {
    const sessionId = localStorage.getItem('sessionId');
    const res = await createEngagement(streamId, 'wheel', {
      questionId: currentRosFeature.id,
      title: currentRosFeature.title,
      brandColours: {},
      settings: {},
      sessionId,
      type: currentRosFeature.type
    });

    if (res.status && res.entity) {
      setInteraction(res.entity.engagementId);
      updateFSMeta({ activeInteraction: { id: res.entity.engagementId, type: 'wheel' } });
    }
  };

  const readWheel = async ({ engagementId }) => {
    setInteraction(engagementId);
  };

  useEffect(() => {
    setShowRosModel(false);
  }, [currentRosFeature]);

  const showResult = (options) => {
    if (options) {
      const resultArr = reArrangeOptions(options);
      setIsResultView(true);
      const updateKey = resultArr.map((res) => ({ ...res, body: res.data }));
      setResult(updateKey);
    }
  };

  useEffect(() => {
    if ((!currentRosFeature.options || currentRosFeature.options.length < 2) && currentRosFeature.type !== 'newWheel') {
      // eslint-disable-next-line no-param-reassign
      currentRosFeature.options = [{ body: 'Option 1' }, { body: 'Option 2' }];
    }

    if (!isRos) {
      showResult(currentRosFeature.options);
    } else if (currentRosFeature.type !== 'newWheel') {
      setOpts(currentRosFeature.options);
    }
  }, [currentRosFeature.options]);

  useEffect(() => {
    if (!isRos) return;
    if (currentRosResult.length) {
      const prevResult = currentRosResult[0];
      if (prevResult.type !== 'newWheel') {
        showResult(prevResult.options);
      }
    } else if (currentRosFeature.type === 'newWheel') {
      setOpts(currentRosFeature.options);
    }
  }, [currentRosResult]);

  useEffect(() => {
    (async () => {
      if (winner && wheelId) {
        const response = await updateEngagement(wheelId, currentRosFeature.type, { winner, type: 'winner', streamId });

        if (response.status) {
          setWinner(null);
          setWheelId(null);
        }
      }
    })();
  }, [wheelId, winner]);

  useEffect(() => {
    if (splitDiv) {
      setIsResultView(false);
    }
  }, [splitDiv]);

  useEffect(() => {
    const hiddenIds = hiddenData[currentRosFeature.id] || [];

    const filterOptions = currentRosFeature.options.filter((o) => !hiddenIds.includes(o.id));

    setOpts(filterOptions);
  }, [hiddenData]);

  const turncateWinningWheelOpt = (text) => {
    if (text && text.length > 22) {
      return `${text.slice(0, 22)}...`;
    }
    return text;
  };

  const getUserNameOfCommentor = async () => {
    if (isRos) {
      const hiddenIds = hiddenData[currentRosFeature.id] || [];
      const wheelUser = currentRosFeature.setting.wheelShowUser;
      const getCommentDetails = await getCommentsDetails(streamId, wheelUser);
      if (getCommentDetails.status) {
        if (getCommentDetails.entity.length) {
          setOpts(getCommentDetails.entity.map((user) => {
            if (!hiddenIds.includes(user.id)) {
              return { id: user.id, body: turncateWinningWheelOpt(user.body) };
            }
            return undefined;
          }).filter((val) => val !== undefined));

          setNewWheelUser(getCommentDetails.entity.map((user) => (
            { id: user.id, body: turncateWinningWheelOpt(user.body) })));
          setNoUser(false);
          setInteractionState('running');
        } else {
          setInteractionState('no data');
          setNoUser(true);
          setOpts([]);
        }
      }
    }
  };

  const getUserNameOfFeatureComments = () => {
    getFeatureComments({
      question: currentRosFeature,
      streamId,
      hiddenData,
      setWheelState,
      setInteractionState,
      turncateWinningWheelOpt,
      setNoUser,
      setOpts,
      setNewWheelUser
    });
  };

  const startWheel = () => {
    setWinnerInfo(null);
    setIsResultView(false);
    setWheelState('spin');
    setIsExploding(false);
  };

  useEffect(() => {
    if (currentRosFeature.type === 'newWheel') {
      if (currentRosFeature.featureId && currentRosFeature.featureId !== streamId) {
        getUserNameOfFeatureComments();
      } else {
        getUserNameOfCommentor();
      }
    }
  }, [hiddenData]);

  useEffect(() => {
    if (currentRosFeature.type === 'newWheel') {
      if (currentRosFeature.featureId && currentRosFeature.featureId !== streamId) {
        getUserNameOfFeatureComments();
        setInteractionState('running');
      } else {
        getUserNameOfCommentor();
        setInteractionState('running');
      }
    }
  }, [currentRosFeature]);

  useEffect(() => {
    if (startInteraction) {
      if (!moderationModeRef.current) {
        createWheel();
        startWheel();
      }
    }
  }, [startInteraction]);

  useEffect(() => {
    if (meta?.wheel?.winnerId !== undefined) {
      setFsWinnerId(() => meta.wheel.winnerId);
      if (meta?.wheel?.wheelId) {
        readWheel({ engagementId: meta.wheel.wheelId });
      }
      startWheel();
    }
  }, [meta]);

  useImperativeHandle(ref, () => ({
    newWheelUser
  }));

  const removeParticipant = () => {
    const hiddenIds = hiddenData[currentRosFeature.id] || [];
    hiddenIds.push(winnerInfo.id);
    setHiddenData({ ...hiddenData, [currentRosFeature.id]: hiddenIds });
    setInteractionState('running');
    setStartInteraction(true);
    setIsStarted(true);
  };

  return (
    <>
      <div id='choice-circle-div' className={`w-full h-full ${interactionState === 'ready' && 'filter blur-lg'} `} >

        {
          isExploding && (
            <ConfettiItems
              isExploding={isExploding}
              setIsExploding={setIsExploding}
              setShowToast={setShowToast}
            />
          )
        }

        <div className='relative h-full rounded-lg overflow-hidden '>
          <div className="h-full grid place-items-center">
            {
              isResultView && <WheelCanvasResult opts={result} />
            }
            {
              (!isResultView && opts.length > 0) && (
                <WheelCanvas
                  key={opts.length}
                  wheelState={wheelState}
                  setWinner={setWinner}
                  setWheelState={setWheelState}
                  stopFeature={stopFeature}
                  splitDiv={splitDiv}
                  opts={opts}
                  isFullScreen={isFullScreen}
                  setIsExploding={setIsExploding}
                  setWinnerInfo={setWinnerInfo}
                  winnerInfo={winnerInfo}
                  winner={winner}
                  updateFSMeta={updateFSMeta}
                  fsWinnerId={fsWinnerId}
                  setFsWinnerId={setFsWinnerId}
                  removeParticipant={removeParticipant}
                  currentRosFeature={currentRosFeature}
                />
              )
            }

            {(isResultView || !opts.length) && noUser
              && <NoUserModal getUserNameOfCommentor={getUserNameOfCommentor} />
            }
            {(isResultView || !opts.length) && !noUser
              && <div className="mx-auto"> <Loader showMessages={false} /> </div>
            }
          </div>

        </div >
      </div >

    </>
  );
});

export default WinnerWheel;
