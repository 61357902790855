import { useEffect, useRef, useState, useContext } from 'react';

import { fabric } from 'fabric';
import { debounce } from 'lodash';
import db from '@services/firebase-service';
import { doc, onSnapshot } from 'firebase/firestore';
import { stopEngagement, getFeatureByQId } from '@services/youtube-platform.service';
import { StreamDataContext } from '@components/context/StreamContext';
import { logToCloudWatch } from '@services/logger.service';

import { CanvasManager } from './talkingTiles/helper';
import { handleEngagement } from '@services/interactions-service';

const loadFont = async (fontName, fontUrl) => {
  const font = new FontFace(fontName, `url(${fontUrl})`);
  await font.load();
  document.fonts.add(font);
};

const MAX_COMMENTS = 500;

const TalkingTiles = ({
  streamId, currentRosResult, currentRosFeature, colorData, streamSettings, platformType,
  startInteraction, setCurrentFeatureId, interactionType, isImpromptu,
  setShowRosModel, moderationModeRef, activeInteractionRef, updateFSMeta,
  setShowNotification, streamSetting, setStartTime
}) => {
  const {
    firestoreUnsub, setFirestoreUnsub
  } = useContext(StreamDataContext);

  const { isRos } = interactionType;
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [ttId, setttId] = useState('');

  // needed to update Canvas rendering
  const [previousData, setPreviousData] = useState([]);
  const resultData = useRef([]);
  const comments = useRef([]);
  const [, setFeatureIdUpdated] = useState(false);
  const [isCommentsEmpty, setIsCommentsEmpty] = useState(true);
  const [commentCounter, setCommentCounter] = useState(0);
  const [colors, setColors] = useState({});
  const [settings, setSettings] = useState({});
  const numCols = useRef(3);

  const [manager, setManager] = useState(null);

  const setupTalkingTiles = ({ id }) => {
    setCommentCounter(0);
    comments.current = [];

    setCurrentFeatureId(id);
    setttId(id);

    if (currentRosResult.length > 0) {
      resultData.current = currentRosResult[0].comments;
      setPreviousData(currentRosResult[0].comments);
    } else {
      resultData.current = currentRosFeature?.comments || [];
      setPreviousData(currentRosFeature?.comments || []);
    }
  };

  const handleTalkingTiles = async ({ interactionId = null }) => {
    const response = await handleEngagement({ streamId, platformType, currentRosFeature, interactionId });

    if (response.status && response.entity) {
      const interactionDetails = {
        id: response.entity.engagementId,
        settings: currentRosFeature.setting,
        colors: currentRosFeature.color
      };
      setupTalkingTiles(interactionDetails);
      updateFSMeta({ activeInteraction: { ...interactionDetails, type: 'talkingTiles' } });
    }
  };

  useEffect(() => {
    if (activeInteractionRef?.current && activeInteractionRef?.current?.type === 'talkingTiles') {
      setupTalkingTiles({
        id: activeInteractionRef.current.id,
        settings: currentRosFeature.setting,
        colors: currentRosFeature.color
      });
    }
  }, [activeInteractionRef.current]);

  const unsubscribeFromFirestore = () => {
    if (firestoreUnsub.unsub) {
      firestoreUnsub.unsub();
    }
  };

  const stopTalkingTiles = async () => {
    unsubscribeFromFirestore();
    const sessionId = localStorage.getItem('sessionId');
    await stopEngagement(streamId, 'talkingTiles', { engagementId: ttId, platformType, sessionId });

    const response = await getFeatureByQId(currentRosFeature.id, streamId, 'talkingTiles');
    if (response.entity.length > 0 && response.entity[0].comments.length > 0) {
      const comments = response.entity[0].comments.reverse();
      resultData.current = comments;
      setPreviousData(comments);
      return;
    }
    resultData.current = [];
    setPreviousData([]);
  };

  const handleStartInteraction = () => {
    if (currentRosResult && currentRosResult.length > 0) {
      handleTalkingTiles({ interactionId: currentRosResult[0].id });
    } else {
      handleTalkingTiles({ interactionId: null });
    }
    setStartTime(Date.now());
  };

  const manageTalkingTilesInteraction = () => {
    if (startInteraction && !moderationModeRef.current) {
      handleStartInteraction();
    } else if (ttId) {
      stopTalkingTiles();
    }
  };

  useEffect(() => {
    setShowRosModel(false);
    manageTalkingTilesInteraction();
  }, [currentRosFeature.id, startInteraction]);

  useEffect(() => {
    comments.current = [];
    setFeatureIdUpdated(true);
    setIsCommentsEmpty(true);
    if (ttId && isRos) {
      const logData = {
        streamId,
        interactionType: 'talkingTiles',
        interactionId: ttId
      };
      setFirestoreUnsub({
        unSub: onSnapshot(doc(db, 'streams', streamId, 'talkingTiles', ttId), (document) => {
          logToCloudWatch('Successfully subscribed to firestore', logData, 'INFO');
          if (document.exists()) {
            setFeatureIdUpdated(false);
            let { commentList } = document.data();
            const filterComments = commentList.filter((comment) => comment?.words?.length > 0);
            if (filterComments.length > 0) setShowNotification(false); filterComments.reverse();
            const tempCommentList = [...filterComments, ...comments?.current];
            comments.current = tempCommentList;

            if (isCommentsEmpty) {
              setIsCommentsEmpty(false);
            }
          }
        }, (error) => logToCloudWatch('Failed to subscribe to firestore', { ...logData, error }, 'ERROR'))
      });
    }
    return () => {
      unsubscribeFromFirestore();
    };
  }, [ttId]);

  const renderCanvas = () => {
    canvasRef.current.renderAll();
    requestAnimationFrame(renderCanvas);
  };

  const initCanvas = async() => {
    await loadFont('Montserrat', '/fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
    if (!canvasRef.current) {
      canvasRef.current = new fabric.Canvas('ttCanvas');
    }
    const backgroundColor = 'rgba(0, 0, 0, 0)';
    canvasRef.current.clear();

    const height = containerRef.current.getBoundingClientRect().height;
    const width = containerRef.current.getBoundingClientRect().width;

    canvasRef.current.setWidth(width);
    canvasRef.current.setHeight(height);
    ['mouse:move', 'mouse:down',
      'mouse:up', 'mouse:out',
      'mouse:over', 'mouse:dblclick', 'mouse:singleclick',
      'mouse:wheel', 'mouse:up:before',
      'mouse:down:before'].forEach(event => {
      canvasRef.current.off(event);
    });
    if (!manager) {
      const initialisedManager = new CanvasManager(canvasRef.current, containerRef.current, currentRosFeature, currentRosFeature.color.talkingTilesBgColor);
      initialisedManager.setBackgroundColor(backgroundColor);
      setManager(initialisedManager);
    } else {
      canvasRef.current?.clear();
      canvasRef.current?.setWidth(width);
      canvasRef.current?.setHeight(height);
      const newManager = manager?.resetCanvas(canvasRef.current, containerRef.current, currentRosFeature);
      newManager.setBackgroundColor(backgroundColor);
      setManager(newManager);
    }
    renderCanvas();
  };

  useEffect(() => {
    return () => {
      if (manager) {
        manager.clearCanvas();
      }
    };
  }, []);

  const resultTile = () => {
    if (!manager) {
      const newManager = new CanvasManager(canvasRef.current, containerRef.current, currentRosFeature, currentRosFeature.color.talkingTilesBgColor);
      setManager(newManager);

      const limitComment = resultData?.current.slice(0, MAX_COMMENTS);

      newManager?.drawResultTile({
        resultData: limitComment,
        numCols: numCols.current,
      });
      return;
    }
    manager?.resetCanvas(canvasRef?.current, containerRef?.current, currentRosFeature);

    manager?.drawResultTile({
      resultData: resultData.current,
      numCols: numCols.current,
    });
  };

  const redrawDroppingTiles = () => {
    if (!manager) {
      const newManager = new CanvasManager(canvasRef.current, containerRef.current, currentRosFeature, currentRosFeature.color.talkingTilesBgColor);
      setManager(newManager);

      newManager.reanimateTiles(numCols.current);
    } else {
      manager.reanimateTiles(numCols.current);
    }
  };

  const manageTiles = () => {
    if (!startInteraction) {
      resultTile();
      return;
    }

    initCanvas();
  };

  useEffect(() => {
    if (!canvasRef.current) {
      initCanvas();
    } else {
      const resize = () => {
        if (!startInteraction) {
          resultTile();
        } else {
          redrawDroppingTiles();
        }
      };

      const debouncedResize = debounce(() => resize(), 500);

      window.addEventListener('resize', debouncedResize, false);

      return () => {
        window.removeEventListener('resize', debouncedResize, false);
      };
    }
  }, [currentRosFeature.id, startInteraction]);

  useEffect(() => {
    if (manager) {
      manager.dropTile({
        comments: comments?.current,
        numCols: numCols.current,
        commentCounter
      });
    };
  }, [comments?.current?.length]);

  useEffect(() => {
    if (!canvasRef.current) {
      initCanvas();
      return;
    }

    manageTiles();
  }, [currentRosFeature.id, startInteraction, resultData?.current]);

  const className = `h-full ${!startInteraction ? 'overflow-y-scroll scrollbar-hide ' : 'overflow-hidden scrollbar-hide '}`;

  return <div ref={containerRef} className={className} ><canvas id="ttCanvas" /></div>;
};

export default TalkingTiles;
