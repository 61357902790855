import Image from '@atoms/Image';
import { useRef, useEffect, useState } from 'react';
import { drawWheel } from './wheel-canvas.service';

const WheelCanvasResult = ({ opts }) => {
  const pi = Math.PI;
  const sectorAngle = ((2 * pi) / (opts.length));

  const canvasRef = useRef(null);
  const angle = useRef(0);

  const [radius, setRadius] = useState();
  const [value, setValue] = useState();
  const [color, setColor] = useState();
  const [canvasSide, setCanvasSide] = useState();

  const createWheel = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const isResult = true;
    drawWheel(canvas, radius, opts, canvasSide, isResult);
  };

  useEffect(() => {
    const hero = document.getElementById('hero');
    const marginY = 90; // Standard margin from top and bottom
    setRadius(parseInt((hero.clientHeight - marginY) / 2, 10));
    setCanvasSide((hero.clientHeight - marginY + 20));
  }, []);

  const clear = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const rotate = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    clear();
    ctx.save();
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(angle.current);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);
    createWheel();
    ctx.restore();
  };

  useEffect(() => {
    const index = opts.findIndex((sr) => sr.isWinner);
    if (radius) {
      if (opts[index].isWinner) {
        setValue(opts[index].body);
        setColor(opts[index].color);
      } else {
        setValue();
        setColor();
      }

      const i = opts.length * 2 - index;
      angle.current = i * sectorAngle - sectorAngle / 2;
      rotate();
    }
  }, [radius, opts]);

  return <>
    {radius
      && <div className='flex gap-x-9'>
        <div style={{ position: 'relative', width: `${canvasSide}px`, height: `${canvasSide}px` }} >
          <canvas ref={canvasRef} width={canvasSide} height={canvasSide} />
          {
            value && <img style={{ top: `${(radius - 7)}px` }} src='/images/wheel_marker.svg' className='absolute -right-9' />
          }
        </div>
        <div style={{ backgroundColor: color }} className=" relative text-white font-Poppins rounded-2xl px-4 py-3 text-center md:w-60 lg:w-104 font-medium break-all h-fit">
          <Image className="absolute -top-4" src="/images/InteractionPage/crown.svg" />
          {value && <p className='text-2xl font-semibold truncate'>{value}</p>}
        </div>
      </div>
    }

  </>;
};

export default WheelCanvasResult;
