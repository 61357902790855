/* eslint-disable max-len */
import {
  createEngagement, stopEngagement, updateEngagement, getFeatureByQId
} from '@services/youtube-platform.service';
import Poll from '@components/Features/Poll';
import Map from '@components/Features/Map';
import Wordcloud from '@components/Features/Wordcloud';
import QuickQuestions from '@components/Features/QuickQuestions';
import TransientThought from '@components/Features/TransientThought';
import TalkingTiles from '@components/Features/TalkingTiles';
import WinnerWheel from '@components/Features/WinnerWheel/WinnerWheel';
import Presentations from '@components/Features/Presentations';
import LinkLibrary from '@components/Features/LinkLibrary';
import EmojisEverywhere from '@components/Features/EmojisEverywhere';
import TextTrack from '@components/Features/TextTrack';
import FantasticFans from '@components/Features/FantasticFans';
import RatingPolls from '@components/Features/RatingPolls';

export const getFeatureData = async (currentRosFeature, streamId) => {
  const response = await getFeatureByQId(currentRosFeature.id, streamId, currentRosFeature.type);
  if (response.status && response.entity.length !== 0) {
    return true;
  }
  return false;
};

export const createFeature = async ({
  streamId, platformType, currentRosFeature,
  type, setId, setFeatureId, setPollOptions, dispatch, setPollType
}) => {
  const startTime = new Date().getTime();

  const createdFeature = await getFeatureData(currentRosFeature, streamId);

  if (!createdFeature) {
    const sessionId = localStorage.getItem('sessionId');
    const response = await createEngagement(streamId, type, {
      startTime,
      title: currentRosFeature.title,
      options: currentRosFeature.options,
      platformType,
      questionId: currentRosFeature.id,
      brandColours: currentRosFeature.color,
      settings: currentRosFeature.setting,
      sessionId
    });

    if (response.status && response.entity) {
      setId(response.entity.engagementId);
      setFeatureId(response.entity.engagementId);

      if (type === 'poll') {
        const typeOfPoll = currentRosFeature.options.length > 1 ? 'pollWithOptions' : 'freeForm';
        setPollType(typeOfPoll);
        if (typeOfPoll === 'pollWithOptions') {
          setPollOptions(
            currentRosFeature.options.map(
              (option) => ({ name: option.body, weight: 0, totalWeight: 0 })
            )
          );
        } else {
          dispatch({ type: 'update', data: [] });
        }
      }
    }
  }
};

export const createImpromptuFeature = async ({
  streamId, platformType, featureBrandColours, featureSettings, feature,
  type, setId, setFeatureId, setPollOptions, dispatch, setPollType
}) => {
  const startTime = new Date().getTime();
  const sessionId = localStorage.getItem('sessionId');
  const response = await createEngagement(streamId, type, {
    startTime,
    title: feature.title,
    options: feature.options,
    platformType,
    questionId: feature.id,
    brandColours: featureBrandColours,
    settings: featureSettings,
    sessionId
  });

  if (response.status && response.entity) {
    setId(response.entity.engagementId);
    setFeatureId(response.entity.engagementId);

    if (type === 'poll') {
      const typeOfPoll = feature.options.length > 1 ? 'pollWithOptions' : 'freeForm';
      setPollType(typeOfPoll);

      if (typeOfPoll !== 'freeForm') {
        setPollOptions(
          feature.options.map(
            (option) => ({ name: option.body, weight: 0, totalWeight: 0 })
          )
        );
      } else {
        dispatch({ type: 'update', data: [] });
      }
    }
  }
};

export const stopFeature = async ({
  unsubscribeFromFirestore, streamId, featureId, setIsPaused, _setExclusionList, type, onPlayClick, platformType
}) => {
  unsubscribeFromFirestore();
  const sessionId = localStorage.getItem('sessionId');
  const response = await stopEngagement(streamId, type, { engagementId: featureId, platformType, sessionId });
  await updateEngagement(featureId, type, { buttonState: 'new', type: 'buttonState' });

  if (response.status) {
    if (type === 'poll') {
      _setExclusionList([]);
      setIsPaused(false);
    }

    if (type === 'wordCloud') {
      setIsPaused(false);
      onPlayClick();
    }
  }
};

export const getStopPollComments = (features) => {
  if (features !== null) {
    return features.reduce((accumlator, poll) => {
      const pollObj = {};

      const totalWeight = poll.processedComments.reduce((sum, obj) => {
        // eslint-disable-next-line no-param-reassign
        sum += obj.weight;
        return sum;
      }, 0);

      const processedCommentsPercentage = poll.processedComments.reduce((commentsArray, obj) => {
        const objOfproceedComments = {};
        const { name } = obj;
        let percentage;
        if (totalWeight === 0) {
          percentage = 0;
        } else {
          percentage = Number(((obj.weight / totalWeight) * 100).toFixed(2));
        }
        objOfproceedComments.name = name;
        objOfproceedComments.weight = percentage;
        objOfproceedComments.totalWeight = totalWeight;
        objOfproceedComments.count = obj.weight;
        commentsArray.push(objOfproceedComments);
        return commentsArray;
      }, []);

      pollObj.id = poll.id;
      pollObj.title = poll.title;
      pollObj.comments = processedCommentsPercentage;
      pollObj.type = poll.type || 'freeForm';
      pollObj.featureType = 'Power Poll';
      pollObj.createdTime = poll.createdAt;
      pollObj.updatedTime = poll.updatedAt;
      pollObj.questionId = poll.question_id;
      pollObj.settings = poll.settings;
      pollObj.colours = poll.brand_colours;

      accumlator.push(pollObj);

      return accumlator;
    }, []);
  }
  return [];
};

export const getStopMapComments = (features) => {
  if (features[0] === null) {
    return [];
  }

  return features.reduce((accumlator, map) => {
    const mapObj = {};
    mapObj.features = map.processedComments.map((comment) => comment.geo_json);
    mapObj.id = map.id;
    mapObj.title = map.title;
    mapObj.button_state = map.button_state;
    mapObj.featureType = 'Magic Map';
    mapObj.createdTime = map.createdAt;
    mapObj.updatedTime = map.updatedAt;
    mapObj.questionId = map.question_id;
    mapObj.settings = map.settings;
    mapObj.colours = map.brand_colours;

    accumlator.push(mapObj);

    return accumlator;
  }, []);
};

export const getOnFeature = (props) => {
  if (props.currentRosFeature) {
    if (props.currentRosFeature.type === 'pollWithOptions') {
      return <Poll {...props} pollData={props.data}
      />;
    }

    if (props.currentRosFeature.type === 'freeForm') {
      return <Poll {...props} pollData={props.data}
      />;
    }

    if (props.currentRosFeature.type === 'map') {
      return <Map {...props} mapData={props.data}
      />;
    }

    if (props.currentRosFeature.type === 'wordCloud') {
      return <Wordcloud {...props} wordCloudData={props.data}
      />;
    }

    if (props.currentRosFeature.type === 'wheel') {
      return <WinnerWheel {...props} />;
    }

    if (props.currentRosFeature.type === 'newWheel') {
      return <WinnerWheel {...props} />;
    }

    if (props.currentRosFeature.type === 'transientThought') return <TransientThought {...props} />;

    if (props.currentRosFeature.type === 'PDF Slide') return <Presentations {...props} />;

    if (props.currentRosFeature.type === 'Quick Questions') return <QuickQuestions {...props} questionData={[]} totalQuestions={0} />;

    if (props.currentRosFeature.type === 'linkLibrary') return <LinkLibrary {...props} linkData={[]} totalLinks={0} />;

    if (props.currentRosFeature.type === 'emojisEverywhere') return <EmojisEverywhere {...props} emojisEverywhereData={props.data} />;

    if (props.currentRosFeature.type === 'talkingTiles') return <TalkingTiles {...props} />;

    if (props.currentRosFeature.type === 'textTrack') return <TextTrack {...props} isResult={false} />;

    if (props.currentRosFeature.type === 'fantasticFans') return <FantasticFans {...props} />;

    if (props.currentRosFeature.type === 'ratingPolls') return <RatingPolls {...props} isResult={false} />;

    if (props.currentRosFeature.type === 'blankScreen') return <div className='w-full h-full' />;
  }

  return null;
};
