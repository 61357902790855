/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
  useEffect, useReducer, useRef, useState, useContext,
  forwardRef, useImperativeHandle
} from 'react';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import { doc, onSnapshot } from 'firebase/firestore';
import db from '@services/firebase-service';
import { logToCloudWatch } from '@services/logger.service';
import {
  updateFreeFormPoll, getFreeFormPollComments, stopEngagement
} from '@services/youtube-platform.service';
import { StreamDataContext } from '@components/context/StreamContext';
import PollOption from '@components/Streams/PollOption';
import { pushDataLayerForEvent } from '@lib/gtag';

import { initialOptions, pollReducer } from '@components/Features/pollState/reducer';
import defaultPollOption from '@components/Features/pollState/defaultPollOption';

import { handleEngagement, getOptionsName } from '@services/interactions-service';

import useFonts from '@lib/hooks/useFonts';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts);
}

const Poll = forwardRef(({
  streamId, platformType, currentRosFeature, currentRosResult, interactionType, setCurrentFeatureId, startInteraction,
  splitDiv, dispatchTab, isFullScreen, setShowRosModel, interactionState,
  moderationModeRef, activeInteractionRef, updateFSMeta,
  setShowNotification, setStartTime
}, ref) => {
  const {
    firestoreUnsub, setFirestoreUnsub, setOverrideDismissStatus, setFeatureId
  } = useContext(StreamDataContext);

  const { isResult, isRos } = interactionType;
  const [pollId, setPollId] = useState(currentRosFeature.id);
  const [pollType, setPollType] = useState(currentRosFeature.type);
  const [pollColors, setPollColors] = useState(currentRosFeature.color);
  const [pollSettings, setPollSettings] = useState(currentRosFeature.setting);
  const [pollOptions, dispatch] = useReducer(pollReducer, initialOptions(currentRosFeature, currentRosResult, isResult));

  const [isDeleteUpdate, _setIsDeleteUpdate] = useState(false);
  const isDeleteUpdateRef = useRef(isDeleteUpdate);
  const setIsDeleteUpdate = (data) => {
    isDeleteUpdateRef.current = data;
    _setIsDeleteUpdate(data);
  };

  const [isPaused, _setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const setIsPaused = (data) => {
    isPausedRef.current = data;
    _setIsPaused(data);
  };

  const [exclusionList, _setExclusionList] = useState([]);
  const exclusionListRef = useRef(exclusionList);
  const setExclusionList = (data) => {
    exclusionListRef.current = [...exclusionListRef.current, ...data];
    _setExclusionList([...exclusionList, ...data]);
  };

  const { getFontStyle } = useFonts();
  const fontStyle = getFontStyle(pollSettings.FontStyle || 'Sans-Serif');

  const setupPoll = ({ id, settings, colors, options }) => {
    setFeatureId(id);
    setCurrentFeatureId(id);
    setPollId(id);

    const interactionType = options.length > 1 ? 'pollWithOptions' : 'freeForm';
    setPollType(interactionType);

    setPollColors(colors);
    setPollSettings(settings);
    dispatch({ type: 'UPDATE_OPTION', options });
  };

  const handlePoll = async ({ interactionId = null }) => {
    const response = await handleEngagement({ streamId, platformType, currentRosFeature, interactionId });

    if (response.status && response.entity) {
      const interactionDetails = {
        id: response.entity.engagementId,
        settings: currentRosFeature.setting,
        colors: currentRosFeature.color,
        options: initialOptions(currentRosFeature, currentRosResult, false)
      };
      setupPoll(interactionDetails);
      updateFSMeta({ activeInteraction: interactionDetails });
    }
  };

  useEffect(() => {
    _setExclusionList([]);
    exclusionListRef.current = [];
  }, [pollId]);

  const deleteComment = async (text) => {
    setExclusionList(text);
    const res = await updateFreeFormPoll(pollId, { text, streamId, type: currentRosFeature.type });

    const updatedOptions = pollOptions.reduce((accumlator, option) => {
      if (exclusionListRef.current.includes(option.name)) {
        accumlator.push({ ...option, weight: 0 });
      } else {
        accumlator.push(option);
      }

      return accumlator;
    }, []);

    dispatch({ type: 'UPDATE_OPTION', options: [...updatedOptions, ...defaultPollOption().slice(updatedOptions.length)] });
    return res;
  };

  const unsubscribeFromFirestore = () => {
    if (firestoreUnsub.unsub) {
      firestoreUnsub.unsub();
    }
  };

  const getHighestCountComments = (dataList) => {
    if (dataList.length > 10) {
      return dataList.slice(0, 10);
    }
    return dataList;
  };

  useEffect(() => {
    if (pollId && isRos) {
      const logData = {
        streamId,
        interactionType: pollType,
        interactionId: pollId
      };
      setFirestoreUnsub({
        unsub: onSnapshot(doc(db, 'streams', streamId, 'poll', pollId), (document) => {
          logToCloudWatch('Successfully subscribed to firestore', logData, 'INFO');
          if (document.exists() && !isPausedRef.current && !isDeleteUpdateRef.current) {
            if (currentRosFeature.type === 'freeForm') {
              const dataList = document.data().data.filter((x) => !exclusionListRef.current.includes(x.name));
              const comments = getHighestCountComments(dataList);

              if (comments.length > 0) setShowNotification(false);

              dispatch({ type: 'UPDATE_OPTION', options: [...comments, ...defaultPollOption().slice(comments.length)] });
              dispatchTab({ data: comments, type: 'UPDATE_TAB' });
            } else {
              const comments = document.data().data;

              if (comments.length > 0) setShowNotification(false);

              dispatch({ type: 'UPDATE_OPTION', options: comments });
            }
          }
          setIsDeleteUpdate(false);
        }, (error) => logToCloudWatch('Failed to subscribe to firestore', { ...logData, error }, 'ERROR'))
      });
    }

    return () => {
      unsubscribeFromFirestore();
    };
  }, [pollId, isPausedRef.current]);

  useEffect(() => {
    if (activeInteractionRef.current?.id && activeInteractionRef.current?.type?.match(/(pollWithOptions|freeForm)/)) {
      setupPoll({
        id: activeInteractionRef.current.id,
        colors: activeInteractionRef.current.colors,
        settings: activeInteractionRef.current.settings,
        options: activeInteractionRef.current.options
      });
    }
  }, [activeInteractionRef.current]);

  const stopPoll = async () => {
    unsubscribeFromFirestore();
    const sessionId = localStorage.getItem('sessionId');
    await stopEngagement(streamId, currentRosFeature.type, { engagementId: pollId, platformType, sessionId });
  };

  const handleStartInteraction = () => {
    if (currentRosResult && currentRosResult.length > 0) {
      handlePoll({ interactionId: currentRosResult[0].id });
    } else {
      handlePoll({ interactionId: null });
    }
    setStartTime(Date.now());
  };

  const handleStopInteraction = () => {
    stopPoll();
    SendDataToGoogleAnalytics();
    setPollId('');
  };

  const managePollInteraction = () => {
    if (!isResult && startInteraction) {
      handleStartInteraction();
    } else if (pollId) {
      handleStopInteraction();
    }
  };

  useEffect(() => {
    setShowRosModel(false);
    if (moderationModeRef.current) return;

    managePollInteraction();

    setOverrideDismissStatus(new Date().getTime());
  }, [currentRosFeature.id, startInteraction]);

  useEffect(() => {
    dispatch({ type: 'UPDATE_OPTION', options: initialOptions(currentRosFeature, currentRosResult, false) });
  }, [currentRosFeature.id, currentRosResult]);

  const getFreeFormPollProcessedCommentsCount = async () => {
    const response = await getFreeFormPollComments(pollId);
    if (response && response.status && response.entity.processedComments.length > 0) {
      pushDataLayerForEvent('end_power_poll_interaction', { no_comments: response.entity.processedComments.length });
    }
  };

  const getPollWithOptionsProcessedCommentsCount = () => pushDataLayerForEvent('end_power_poll_interaction', { no_comments: pollOptions[0]?.totalWeight }); // seems incorrect

  const SendDataToGoogleAnalytics = () => {
    pollType === 'pollWithOptions' ? getPollWithOptionsProcessedCommentsCount() : getFreeFormPollProcessedCommentsCount();
  };

  const handleEditBtn = () => {
    if (isPaused) {
      setIsPaused(false);
    } else {
      setIsPaused(true);
    }
  };

  useImperativeHandle(ref, () => ({
    handleEditBtn, deleteComment
  }));

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [isFullScreen]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [splitDiv]);

  return (
    <>
      <div className={'h-full overflow-hidden '}>
        <div className={`relative overflow-hidden rounded-lg w-full h-full ${interactionState === 'ready' && 'filter blur-lg'}`}>
          {fontStyle && pollOptions && pollColors && <div className='px-6  w-full h-full relative'>
            <div className={`w-full h-full flex justify-evenly py-2 gap-2 flex-col  ${pollOptions.length > 2 ? 'justify-between' : 'justify-center'}`}>
              {
                pollOptions.map((option, index) => <PollOption
                  key={option.name}
                  optionNumber={`${getOptionsName(currentRosFeature, index)}`} name={`${option.name}`} id={index} option={option} totalCount={pollOptions.reduce((sum, obj) => sum + obj.count, 0)} count={option.count || 0} weight={option.weight || 0} relativePercentage={option.relativePercentage || 0} color={pollColors.pollChartColor}
                  currentRosFeature={currentRosFeature} pollOptions={pollOptions} pollColors={pollColors} pollSettings={pollSettings}
                  fontFamily={fontStyle}
                />)
              }
            </div>
          </div>}

        </div>
      </div >
    </>
  );
});

export default Poll;
