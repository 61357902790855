/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useReducer, useRef, useState, forwardRef, useImperativeHandle
} from 'react';
import Highcharts from 'highcharts';
import { doc, onSnapshot } from 'firebase/firestore';
import db from '@services/firebase-service';
import { updateWordCloud, stopEngagement } from '@services/youtube-platform.service';
import { logToCloudWatch } from '@services/logger.service';
import { StreamDataContext } from '@components/context/StreamContext';
import { pushDataLayerForEvent } from '@lib/gtag';
import WordChart from './WordChart';
import { handleEngagement } from '@services/interactions-service';
import { chromaPaletteParser } from './helper/chromaPaletteParser';
import { colorPalette } from '@lib/constants';
import useFonts from '@lib/hooks/useFonts';

const Wordcloud = forwardRef(({
  streamId, platformType, currentRosFeature, currentRosResult, interactionType, setCurrentFeatureId, startInteraction,
  splitDiv, dispatchTab, comments, isFullScreen, setShowRosModel, interactionState,
  moderationModeRef, setMessages, activeInteractionRef, updateFSMeta,
  setShowNotification, setStartTime
}, ref) => {
  const { isRos, isResult } = interactionType;

  const {
    firestoreUnsub,
    setFirestoreUnsub,
    setOverrideDismissStatus,
    setFeatureId
  } = useContext(StreamDataContext);

  const [wordCloudId, setWordCloudId] = useState(currentRosFeature.id);
  const [wordCloudColors, setWordCloudColors] = useState(currentRosFeature.color);
  const [wordCloudSettings, setwordCloudSettings] = useState(currentRosFeature.setting);

  const [isPaused, _setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);

  const { getFontStyle } = useFonts();
  const { fontFamily } = getFontStyle(wordCloudSettings.FontStyle || 'Sans-Serif');

  const setIsPaused = (data) => {
    isPausedRef.current = data;
    _setIsPaused(data);
  };

  const [exclusionList, _setExclusionList] = useState([]);
  const exclusionListRef = useRef(exclusionList);
  const setExclusionList = (data) => {
    exclusionListRef.current = [...exclusionListRef.current, data];
    _setExclusionList([...exclusionList, data]);
  };

  const [gtagTriggerCounter, setGtagTriggerCounter] = useState(0);

  const newPalette = chromaPaletteParser().parse(colorPalette, wordCloudColors.wcBgColor);

  const getWordCloudData = () => {
    if (currentRosResult.length > 0 && currentRosResult[0].comments.length > 0) {
      return currentRosResult[0].comments;
    }
    return currentRosFeature.comments;
  };

  const highchartsRef = useRef(null);

  const options = {
    exporting: { enabled: false },
    chart: {
      backgroundColor: 'rgba(255, 255, 255, 0.0)'
    },
    plotOptions: {
      series: {},
      chart: { height: '100%' },
      wordcloud: {
        rotation: {
          from: 0,
          orientations: wordCloudSettings?.Vertical ? 2 : 1,
          to: wordCloudSettings?.Vertical ? 90 : 0
        },
        color: wordCloudColors.wcWordColor || '#000000',
        colorByPoint: wordCloudColors.multipleColors,
        colors: wordCloudColors.multipleColors ? newPalette : [wordCloudColors.wcWordColor || '#000000'],
        style: {
          fontFamily
        }
      }
    },

    credits: {
      enabled: false
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }]
    },
    series: [{
      type: 'wordcloud',
      data: getWordCloudData(),
      name: 'Count'
    }],
    title: { text: '' }
  };

  const [chartOptions, dispatch] = useReducer((state, action) => {
    if (action.data?.length > 0) {
      setGtagTriggerCounter(1);
      if (gtagTriggerCounter === 0) pushDataLayerForEvent('populate_comments_wonder_words');
    }

    return {
      ...state,
      series: [{
        type: 'wordcloud',
        data: action.data,
        name: 'Count'
      }],
      chart: {
        backgroundColor: 'rgba(255, 255, 255, 0.0)'
      },
      credits: {
        enabled: false
      },

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },

      plotOptions: {
        series: {},
        chart: { height: '100%' },
        wordcloud: {
          rotation: {
            from: 0,
            orientations: wordCloudSettings.Vertical ? 2 : 1,
            to: wordCloudSettings.Vertical ? 90 : 0
          },
          color: wordCloudColors.wcWordColor,
          colorByPoint: wordCloudColors.multipleColors,
          colors: wordCloudColors.multipleColors ? newPalette : [wordCloudColors.wcWordColor || '#000000'],
          style: {
            fontFamily: wordCloudSettings.FontStyle
          }
        }
      }
    };
  }, options);

  const setPreviousData = () => {
    if (currentRosResult.length > 0 && currentRosResult[0].comments.length > 0) {
      dispatch({ type: 'update', data: currentRosResult[0].comments });
    } else {
      dispatch({ type: 'update', data: [] });
    }
  };

  const setupWordCloud = ({ id, settings, colors }) => {
    dispatch({ data: [] });

    setFeatureId(id);
    setCurrentFeatureId(id);
    setWordCloudId(id);
    setWordCloudColors(colors);
    setwordCloudSettings(settings);

    setPreviousData();
  };

  const handleWordCloud = async ({ interactionId = null }) => {
    const response = await handleEngagement({ streamId, platformType, currentRosFeature, interactionId });

    if (response.status && response.entity) {
      const interactionDetails = {
        id: response.entity.engagementId,
        settings: currentRosFeature.setting,
        colors: currentRosFeature.color
      };
      setupWordCloud(interactionDetails);
      updateFSMeta({ activeInteraction: { ...interactionDetails, type: 'wordCloud' } });
    }
  };

  const unsubscribeFromFirestore = () => {
    if (firestoreUnsub.unsub) {
      firestoreUnsub.unsub();
    }
  };

  useEffect(() => {
    if (wordCloudId && isRos) {
      const logData = {
        streamId,
        interactionType: 'wordcloud',
        interactionId: wordCloudId
      };
      setFirestoreUnsub({
        unsub: onSnapshot(doc(db, 'streams', streamId, 'wordcloud', wordCloudId), (document) => {
          logToCloudWatch('Successfully subscribed to firestore', logData, 'INFO');
          if (document.exists()) {
            if (isPausedRef.current) {
              return;
            }
            const dataList = document.data().data.filter((x) => !exclusionListRef.current.includes(x.name));

            if (dataList.length > 0) setShowNotification(false);

            dispatchTab({ type: 'UPDATE_TAB', data: dataList });
            dispatch({ type: 'update', data: dataList });
          }
        }, (error) => logToCloudWatch('Failed to subscribe to firestore', { ...logData, error }, 'ERROR'))
      });
    }

    return () => {
      unsubscribeFromFirestore();
    };
  }, [wordCloudId, isPausedRef.current]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
    dispatch({ type: 'update', data: comments });
  }, [splitDiv]);

  const onPauseClick = () => {
    const { charts } = Highcharts;
    const chart = charts[charts.length - 1];
    chart.series[0].chart.delImgs = [];
  };

  const deleteComment = async (text) => {
    /**
       We don't want to get the wrong index as data keeps on changing on Moderation Mode.
       Therefore, we pause it while the deletion process is on.
     */
    if (moderationModeRef.current) setIsPaused(true);
    const res = await updateWordCloud({ id: wordCloudId, data: { streamId, word: text.toLowerCase() } });
    if (res.status) {
      const { charts } = Highcharts;
      const chart = charts[charts.length - 1];
      const cComments = chart.series[0].data.map((comment) => comment.name);
      const index = cComments.indexOf(text);
      if (index > -1) {
        const wordToDelete = chart.series[0].data[index];
        setExclusionList(wordToDelete.name.toLowerCase());
        wordToDelete.graphic.hide();
      }
    } else {
      setMessages([res.message]);
    }
    if (moderationModeRef.current) setIsPaused(false);
    return res;
  };

  const onPlayClick = () => {
    const { charts } = Highcharts;
    const chart = charts[charts.length - 1];
    chart?.series[0]?.chart?.delImgs?.forEach((img) => {
      img.hide();
    });
    if (chart?.series[0]?.chart?.delImgs) { chart.series[0].chart.delImgs = []; }
  };

  const SendDataToGoogleAnalytics = () => {
    const { charts } = Highcharts;
    const chart = charts[charts.length - 1];
    pushDataLayerForEvent('end_wonder_words_interaction', { no_comments: chart?.series[0].data?.length });
  };

  const stopWordCloud = async () => {
    unsubscribeFromFirestore();
    const sessionId = localStorage.getItem('sessionId');
    await stopEngagement(streamId, 'wordCloud', { engagementId: wordCloudId, platformType, sessionId });
  };

  useEffect(() => {
    if (activeInteractionRef.current?.id && activeInteractionRef.current?.type === 'wordCloud') {
      setupWordCloud({
        id: activeInteractionRef.current.id,
        colors: activeInteractionRef.current.colors,
        settings: activeInteractionRef.current.settings
      });
    }
  }, [activeInteractionRef.current]);

  const handleStartInteraction = () => {
    if (currentRosResult && currentRosResult.length > 0) {
      handleWordCloud({ interactionId: currentRosResult[0].id });
    } else {
      handleWordCloud({ interactionId: null });
    }
    setStartTime(Date.now());
  };

  const handleStopInteraction = () => {
    stopWordCloud();
    setIsPaused(false);
    onPlayClick();
    SendDataToGoogleAnalytics();
    setWordCloudId('');
  };

  const manageWordCloudInteraction = () => {
    if (!isResult && startInteraction) {
      handleStartInteraction();
    } else if (wordCloudId) {
      handleStopInteraction();
    }
  };

  useEffect(() => {
    setShowRosModel(false);
    if (moderationModeRef.current) return;

    manageWordCloudInteraction();

    setOverrideDismissStatus(new Date().getTime());
  }, [currentRosFeature.id, startInteraction]);

  useEffect(() => {
    setPreviousData();
  }, [currentRosFeature.id, currentRosResult]);

  const handleEditBtn = () => {
    if (isPaused) {
      setIsPaused(false); onPlayClick();
    } else {
      setIsPaused(true); onPauseClick();
    }
  };

  useImperativeHandle(ref, () => ({
    handleEditBtn, deleteComment
  }));

  return (
    <div className={`h-full overflow-hidden w-full rounded-lg ${interactionState === 'ready' && 'filter blur-sm'}`}>
      <WordChart
        backgroundColor={'rgba(255, 255, 255, 0.0)'}
        highchartsRef={highchartsRef}
        chartOptions={chartOptions}
        isFullScreen={isFullScreen}
        splitDiv={splitDiv}
        containerProps={{ style: { width: splitDiv ? 'calc(100vw - 380px)' : '100%', height: '100%' } }}
      />
    </div>
  );
});

export default Wordcloud;
